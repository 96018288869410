import {APP_VERSION, IEnvironment} from './environment.interface';

// Habeas Data NORMAL PROD y APP Habeas Data
let hdnp = 'https://habeasdata.nnco.cloud';
// Names
let hdnpNameWeb = "Habeas Data Web";

// Habeas Data VIRTUAL PROD y APP Habeas Data
let hdvp = 'https://virtual.habeasdata.nnco.cloud';
// Names
let hdvpNameWeb = "Habeas Data Virtual Web";

// Localhost 
let dev = "http://127.0.0.1:8000";
let devNameWeb = "Dev pruebas"

export const environment: IEnvironment = {
  production: false,
  apiRoot: hdvp,
  appName: hdvpNameWeb,
  auth: {
    username: 'awilches',
    password: 'novo2014'
  },
  version: APP_VERSION,
    reCaptcha: {
      siteKey: '6LcdlLIZAAAAAKwWTrADf1MEL5VIf8HYp7kn5u89'
    },
  app: false
};